import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { IconButton } from '@mui/material';
import './MyDidimos.css';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { makeStyles } from '@material-ui/styles';
import { Tooltip } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import BallotIcon from '@mui/icons-material/Ballot';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import fbx from './assets/fbx.png';
import red from '@material-ui/core/colors/red';
import './DidimoCard.css';
import { getData, deleteData } from '../../helpers/dataFetching';
import EditDidimoModal from '../../components/EditDidimoModal';
import DeleteModal from '../../components/DeleteModal';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SuccessSnack from '../../components/SuccessSnack';
import ErrorSnack from '../../components/ErrorSnack';
import gltf from './assets/gltf.png';
import DidimoSingle from './DidimoSingle';
import blurredImage from '../../assets/BlurredImage.png';
import { GlobalContext } from '../../context/store';
import {
  updateStatusManager,
  addDownloadStatusManager,
  removeStatusManager,
  removeDownloadStatusManager,
} from 'src/context/actions';
import { DIDIMO_DOWNLOAD_UPDATE_TIME } from 'src/utils/constants';
import MetaInfoModel from './MetaInfoModel';

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    marginLeft: '0',
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}

const useStyles = makeStyles((theme) => ({
  media: {
    cursor: 'pointer',
    height: 225, //300,
    width: 225,
    position: 'relative',
    backgroundImage: `url(${blurredImage})`,
    display: 'inline-block',
  },
  mediaBackground: {
    backgroundSize: '160px',
  },
  moreDetails: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
  },
  moreDetailsButton: {
    marginRight: '10px',
  },
  ribbon: {
    position: 'absolute',
    left: '-5px; top: -5px',
    // zIndex: "1",
    overflow: 'hidden',
    width: '100px; height: 100px',
    textAlign: 'right',
    '& span': {
      fontSize: '13px',
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      textTransform: 'uppercase',
      textAlign: 'center',
      lineHeight: '28px',
      transform: 'rotate(-45deg)',
      '-webkit-transform': 'rotate(-45deg)',
      width: '160px',
      display: 'block',
      background: theme.palette.secondary.main,
      boxShadow: '0 3px 10px -5px rgba(0, 0, 0, 1)',
      position: 'absolute',
      top: '27px; left: -38px',
    },
    '& span::before': {
      content: '',
      position: 'absolute',
      left: '0px',
      top: '100%',
      zIndex: '-1',
      borderLeft: '3px solid ' + theme.palette.secondary.main,
      borderRight: '3px solid transparent',
      borderBottom: '3px solid transparent',
      borderTop: '3px solid ' + theme.palette.secondary.main,
    },
    '& span::after': {
      content: '',
      position: 'absolute',
      right: '0px',
      top: '100%',
      zIndex: '-1',
      borderLeft: '3px solid transparent',
      borderRight: '3px solid ' + theme.palette.secondary.main,
      borderBottom: '3px solid transparent',
      borderTop: '3px solid ' + theme.palette.secondary.main,
    },
  },
  loadingData: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '600px',
  },
  percentage: {
    color: 'white',
    width: '100%',
    bottom: '0',
    height: '22px',
    padding: '3px',
    position: 'absolute',
    fontSize: '80%',
    backgroundColor: 'black',
    opacity: '0.5',
  },
  error: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: 'red',
    opacity: 0.7,
    color: 'white',
    fontSize: '80%',
    padding: '3px',
    height: '22px',
  },
}));

const DidimoCard = styled((props) => {
  const {
    accessToken,
    showSample,
    getMetadataStructureHandler,
    metaDataInformation,
    handleLoadMore,
    onCheck,
    onUncheck,
    toDeleteDdmo,
    AllDidimos,
    generatedKey,
    onFavourite,
    setProgress,
    onUpdateDidimo,
    selectedDidimos,
    fetchCurrentPageDidimos,
  } = props;

  const { state, dispatch } = useContext(GlobalContext);
  const [MetaInformation, setMetaInformation] = React.useState(false);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [metaDataInfo, setDdmMetaInfo] = useState();
  const [ddmMetaAPIVersion, setDdmMetaAPIVersion] = React.useState(null);
  const [ddmMetaPackage, setDdmMetaPackage] = React.useState(null);
  const [isEditModal, setisEditModal] = useState(false);
  const [editModalDidimoName, setEditModalDidimoName] = useState('');
  const [isDeleteModal, setisDeleteModal] = useState(false);
  const [ddmokey, setDdmoKey] = useState('');
  const [metadataStructure, setMetadataStructure] = React.useState(null);
  const [errorMessageOpen, setErrorMessageOpen] = useState(false);
  const [disableDownload, setDisableDownload] = useState(true);
  const [showDeleteNotification, setShowDeleteNotification] = useState(false);
  const [ddmDetailsComponent, setDDMDetailsComponent] = useState('');
  const [customerPortalData, setCustomerPortalData] = useState([]);

  const [downloadSuccessMessageOpen, setDownloadSuccessMessageOpen] =
    useState(false);
  const [downloadMessage, setDownloadMessage] = useState(
    'Downloaded hairs successfully'
  );

  useEffect(() => {
    axios
      .get(`/platforms/customer_portal`, {
        headers: { Authorization: `Bearer ${state?.user?.accessToken}` },
      })
      .then(async (res) => {
        await setCustomerPortalData(res.data);
      });
  }, []);

  const [downloadErrorMessageOpen, setDownloadErrorMessageOpen] =
    useState(false);

  const navigate = useNavigate();
  const handleCloseEditModal = (data) => {
    if (data) {
      const didimo = AllDidimos.filter((d) => d.key === data.key)[0];

      var nameItem = didimo.meta_data.find((item) => item.name === 'name');
      if (nameItem) {
        let metadata = didimo.meta_data.map((metaItem) => {
          if (metaItem.name === 'name') {
            metaItem.value = data.metaItem.value;
          }
          return metaItem;
        });
        didimo.meta_data = metadata;
      } else {
        didimo.meta_data.push(data.metaItem);
      }
      onUpdateDidimo(data.key, didimo);
    }
    setisEditModal(false);
    setEditModalDidimoName('');
  };

  const handleCreateEditModal = (ddmo) => {
    setisEditModal(true);
    handleClose();
  };

  const handleCreatedeleteModal = () => {
    setisDeleteModal(!isDeleteModal);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event, key, status, name) => {
    setAnchorEl(event.currentTarget);
    setDdmoKey(key);
    setEditModalDidimoName(name);
    if (status === 'done') {
      setDisableDownload(false);
    } else {
      setDisableDownload(true);
    }
  };

  const getGeneratorUUID = (metadata) => {
    const generatorMeta = metadata.find(
      (item) => item.name === 'generator_uuid'
    );
    return generatorMeta ? generatorMeta.value : null;
  };

  const stopClick = (e) => {
    e.stopPropagation();
  };

  const handleViewDetailClick = async (key) => {
    try {
      if (key) {
        getData(`didimos/${key}`, accessToken).then(async (response) => {
          setDdmMetaInfo(response);
          const generatorUUID = getGeneratorUUID(response.meta_data);
          setMetadataStructure(response);
          let APIVersionData = response.meta_data.find((f) => f.name === 'api');
          let MetaPackageData = response.meta_data.find(
            (f) => f.name === 'generator'
          );
          setDdmMetaAPIVersion(
            APIVersionData !== undefined ? APIVersionData['value'] : 'N/A'
          );
          setDdmMetaPackage(
            MetaPackageData !== undefined ? MetaPackageData['value'] : 'N/A'
          );

          if (MetaPackageData && MetaPackageData['value']) {
            if (customerPortalData?.versions?.length > 0) {
              let version = process.env.REACT_APP_VERSION
                ? process.env.REACT_APP_VERSION
                : '2.5.0';
              const versionData = customerPortalData.versions.find(
                (c) => c.code === version
              );
              let dgpCompatibilityRules = [];
              if (versionData) {
                dgpCompatibilityRules = versionData.dgp_compatibility_rules;
              }
              for (var i = 0; i < dgpCompatibilityRules.length; i++) {
                let regTester = new RegExp(dgpCompatibilityRules[i].pattern);
                let matchRegEx = regTester.test(MetaPackageData['value']);
                if (matchRegEx === true) {
                  await setDDMDetailsComponent(
                    dgpCompatibilityRules[i].settings.didimo_details_component
                  );
                  break;
                }
              }
            }
          }
        });
      }
    } catch (e) {
      console.log('Fetching Didimo Information:', e);
    }
  };

  const downloadImage = (type) => {
    axios
      .get('didimos/' + ddmokey + '/results/' + type)
      .then((res) => {
        window.location.href = res.data.uri;
      })
      .catch((err) => {
        console.log(err);
      });
    handleClose();
  };

  const handleMetaInformation = (didmo) => {
    setMetaInformation(!MetaInformation);
    setDdmMetaInfo(null);
    setDdmMetaAPIVersion(null);
    setDdmMetaPackage(null);
    handleViewDetailClick(didmo.key);
  };

  const handleDddmoDetail = () => {
    navigate(`/didimo/${ddmokey}/details`);
  };

  const ViewGltfPage = () => {
    navigate(`/preview/${ddmokey}`);
  };

  const handleDelete = () => {
    deleteData(`didimos/${ddmokey}`, accessToken)
      .then(() => {
        setisDeleteModal(!isDeleteModal);
        fetchCurrentPageDidimos();
        setShowDeleteNotification(true);
        handleClose();
      })
      .catch(() => {
        fetchCurrentPageDidimos();
        setErrorMessageOpen(true);
      });
  };

  const checkFormat = (type) => {
    const filteredData = AllDidimos.find((item) => item?.key === ddmokey);
    let isExist = [];
    filteredData?.transfer_formats.forEach((item) => {
      isExist.push(item.name);
    });
    if (isExist.includes(type)) {
      return true;
    } else {
      return false;
    }
  };

  const isPreviewAvailable = () => {
    const filteredData = AllDidimos?.find((item) => item?.key === ddmokey);
    const didimoPackageVersion = filteredData?.meta_data?.find(
      (item) => item.name === 'didimo_package_version'
    );
    // Any value is acceptable, for now
    return !!didimoPackageVersion?.value;
  };

  const callDefaultApi = (url) => {
    axios
      .get(url)
      .then((response) => {
        if (response.data.status === 'error') {
          setDownloadErrorMessageOpen(true);
          //setDownloadMessage(response?.data?.status_message ? response?.data?.status_message : 'Error with download');
          setDownloadMessage('Error with download');
          setProgress(response.data.precent);
          const payload = {
            id: ddmokey,
            type: 'download',
            percent: -1,
            status: 'None',
          };
          dispatch(removeStatusManager(payload));
          dispatch(removeDownloadStatusManager());
        } else {
          const payload = {
            id: ddmokey,
            type: 'download',
            percent: response.data.percent,
            status:
              response.data.percent === 100
                ? 'Download complete'
                : 'Downloading package file',
          };

          dispatch(updateStatusManager(payload));

          if (response.data.percent === 100) {
            axios
              .get(response.data.transfer_formats[0]['__links'].self, {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  'DIDIMO-FOLLOW-URI': 'false',
                },
              })
              .then((res) => {
                setDownloadSuccessMessageOpen(true);
                setDownloadMessage('Download complete');
                const payload = {
                  id: ddmokey,
                  type: 'download',
                  percent: 100,
                  status: 'Download complete',
                };
                dispatch(updateStatusManager(payload));

                setTimeout(() => {
                  const payload = {
                    id: ddmokey,
                    type: 'download',
                    percent: -1,
                    status: 'None',
                  };
                  dispatch(removeStatusManager(payload));
                  dispatch(removeDownloadStatusManager());
                }, 2000);

                window.location.href = res.data.uri;
              })
              .catch((err) => {
                const payload = {
                  id: ddmokey,
                  type: 'download',
                  percent: -1,
                  status: 'None',
                };
                dispatch(removeStatusManager(payload));
                dispatch(removeDownloadStatusManager());
                console.log(err);
              });
          } else {
            setTimeout(() => {
              setProgress(response.data.percent);
              callDefaultApi(response.data['__links'].self);
            }, DIDIMO_DOWNLOAD_UPDATE_TIME);
          }
        }
      })
      .catch((err) => {
        const payload = {
          id: ddmokey,
          type: 'download',
          percent: -1,
          status: 'None',
        };
        dispatch(removeStatusManager(payload));
        console.log(err);
      });
  };

  const isShowDownloadHair = () => {
    if (ddmokey) {
      const filteredDDM = AllDidimos.filter((f) => f.key === ddmokey).find(
        (f) => f.key === ddmokey
      );
      return (
        filteredDDM?.meta_data?.find(
          (f) => f.name === 'supports-deformation_matrix' && f.value === 'DMX'
        ) !== undefined
      );
    }
  };

  const handleDownloadHairs = (e) => {
    handleClose();
    setDownloadSuccessMessageOpen(true);
    setDownloadMessage('Processing hairs');

    const payload = {
      id: ddmokey,
      type: 'download',
      percent: -1,
      status: 'Processing hairs',
    };

    dispatch(addDownloadStatusManager(payload));
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${accessToken}`);
    myHeaders.append('Didimo-Platform', process.env.REACT_APP_PLATFORM);
    myHeaders.append('Didimo-Platform-Version', process.env.REACT_APP_VERSION);

    fetch(
      process.env.REACT_APP_API_ROOT +
        'didimos/' +
        ddmokey +
        '/artifacts/deformer_dmx/',
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'DIDIMO-FOLLOW-URI': 'false',
        },
      }
    )
      .then((res) => res.json())
      .then((rsData) => {
        fetch(rsData.uri)
          .then((res) => res.blob())
          .then((blob) => {
            setDownloadSuccessMessageOpen(true);
            setDownloadMessage('Creating Download file');

            const payload = {
              id: ddmokey,
              type: 'download',
              percent: -1,
              status: 'Creating Download file',
            };

            dispatch(updateStatusManager(payload));
            let metadata = {
              type: blob.type,
            };
            let file = new File([blob], 'didimo', metadata);
            const data = new FormData();
            data.append('input_type', 'hairs_deform');
            data.append('template_deformation', file);

            const requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: data,
              redirect: 'follow',
            };
            fetch(process.env.REACT_APP_API_ROOT + 'assets', requestOptions)
              .then((response) => response.json())
              .then((result) => {
                setDownloadSuccessMessageOpen(true);
                setDownloadMessage('Downloading package file, please wait');
                const payload = {
                  id: ddmokey,
                  type: 'download',
                  percent: 0,
                  status: 'Downloading package file',
                };

                dispatch(updateStatusManager(payload));
                callDefaultApi(result['__links'].self);
              })
              .catch((error) => {
                setDownloadErrorMessageOpen(true);
                setDownloadMessage(
                  "You don't have enough points to execute the requested operation."
                );
                console.log('error', error);
              });
          });
      });
  };

  return (
    <div className={props.className}>
      <SuccessSnack
        //open={true}
        open={downloadSuccessMessageOpen}
        onClose={() => setDownloadSuccessMessageOpen(false)}
        message={downloadMessage}
      />
      <ErrorSnack
        open={downloadErrorMessageOpen}
        onClose={() => setDownloadErrorMessageOpen(false)}
        message={downloadMessage}
      />

      <div className="my_didimos">
        <div className="mos_card">
          {AllDidimos.map((ddmo, key) => {
            return (
              <DidimoSingle
                key={key}
                ddmIsFavourite={ddmo?.is_favorite}
                onFavourite={onFavourite}
                ddmokey={ddmo?.key}
                classes={classes}
                handleMetaInformation={handleMetaInformation}
                ddmo={ddmo}
                handleClick={handleClick}
                //checked={toDeleteDdmo.indexOf(ddmo.key) >= 0}
                stopClick={stopClick}
                onCheck={onCheck}
                onUncheck={onUncheck}
                toDeleteDdmo={toDeleteDdmo}
                generatedKey={generatedKey}
                selectedDidimos={selectedDidimos}
                onUpdateDidimo={onUpdateDidimo}
              />
            );
          })}
          <div>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleDddmoDetail}>
                <BootstrapTooltip title="didimo information" placement="right">
                  <div className={classes.moreDetails}>
                    <IconButton
                      className={classes.moreDetailsButton}
                      size="small"
                      aria-label="Didimo information"
                    >
                      {<BallotIcon />}
                    </IconButton>
                    Details
                  </div>
                </BootstrapTooltip>
              </MenuItem>
              {/*view didimo*/}

              <MenuItem
                onClick={ViewGltfPage}
                disabled={
                  !isPreviewAvailable() ||
                  disableDownload ||
                  !checkFormat('gltf')
                }
              >
                <BootstrapTooltip
                  title="Preview didimo information"
                  placement="right"
                >
                  <div className={classes.moreDetails}>
                    <IconButton
                      className={classes.moreDetailsButton}
                      size="small"
                      aria-label="Preview didimo information"
                    >
                      {<VisibilityIcon />}
                    </IconButton>
                    Preview
                  </div>
                </BootstrapTooltip>
              </MenuItem>

              {!showSample && (
                <MenuItem onClick={handleCreateEditModal}>
                  <BootstrapTooltip
                    title="Edit didimo information"
                    placement="right"
                  >
                    <div className={classes.moreDetails}>
                      <IconButton
                        className={classes.moreDetailsButton}
                        size="small"
                      >
                        {<EditIcon />}
                      </IconButton>
                      Edit
                    </div>
                  </BootstrapTooltip>
                </MenuItem>
              )}
              <hr className="divider" />
              {checkFormat('fbx') && (
                <MenuItem
                  onClick={(e) => downloadImage('fbx')}
                  disabled={disableDownload}
                >
                  <BootstrapTooltip
                    title="Download didimo FBX"
                    placement="right"
                  >
                    <div className={classes.moreDetails}>
                      <IconButton
                        className={classes.moreDetailsButton}
                        size="small"
                      >
                        <img
                          alt={fbx}
                          src={fbx}
                          style={{ height: '24px', width: '24px' }}
                        />
                      </IconButton>
                      Download FBX
                    </div>
                  </BootstrapTooltip>
                </MenuItem>
              )}

              {checkFormat('gltf') && (
                <MenuItem
                  onClick={(e) => downloadImage('gltf')}
                  disabled={disableDownload}
                >
                  {' '}
                  <BootstrapTooltip
                    title="Download didimo glTF"
                    placement="right"
                  >
                    <div className={classes.moreDetails}>
                      <IconButton
                        className={classes.moreDetailsButton}
                        size="small"
                      >
                        <img
                          alt={gltf}
                          src={gltf}
                          style={{ height: '24px', width: '24px' }}
                        />
                      </IconButton>
                      Download glTF
                    </div>
                  </BootstrapTooltip>
                </MenuItem>
              )}
              {isShowDownloadHair() && (
                <MenuItem
                  onClick={(e) => handleDownloadHairs(e)}
                  disabled={disableDownload}
                >
                  <BootstrapTooltip title="Download Hair" placement="right">
                    <div className={classes.moreDetails}>
                      <IconButton
                        className={classes.moreDetailsButton}
                        size="small"
                      >
                        {<DownloadForOfflineIcon />}
                      </IconButton>
                      Download Hair
                    </div>
                  </BootstrapTooltip>
                </MenuItem>
              )}

              <hr className="divider" />
              {!showSample && (
                <MenuItem onClick={handleCreatedeleteModal}>
                  <BootstrapTooltip title="Delete didimo" placement="right">
                    <div
                      style={{ marginLeft: 'auto' }}
                      className={classes.moreDetails}
                    >
                      <IconButton
                        className={classes.moreDetailsButton}
                        size="small"
                      >
                        <DeleteOutlinedIcon style={{ color: red[500] }} />
                      </IconButton>
                      Delete
                    </div>
                  </BootstrapTooltip>
                </MenuItem>
              )}
            </Menu>
          </div>
        </div>
      </div>
      <MetaInfoModel
        isOpen={MetaInformation}
        metadataStructure={metadataStructure}
        metaDataInfo={metaDataInfo}
        ddmMetaAPIVersion={ddmMetaAPIVersion}
        ddmMetaPackage={ddmMetaPackage}
        handleMetaInformation={handleMetaInformation}
        version={ddmDetailsComponent}
      />

      <EditDidimoModal
        ddmokey={ddmokey}
        isOpen={isEditModal}
        onCloseModal={handleCloseEditModal}
        accessToken={accessToken}
        ddmoName={editModalDidimoName}
      />

      <DeleteModal
        isOpen={isDeleteModal}
        onCloseModal={handleCreatedeleteModal}
        handleDelete={handleDelete}
      />

      <ErrorSnack
        open={errorMessageOpen}
        onClose={(e) => {
          setErrorMessageOpen(false);
        }}
        message={'An error occurred while processing the operation.'}
      />
      <ErrorSnack
        open={showDeleteNotification}
        onClose={(e) => {
          setShowDeleteNotification(false);
        }}
        message="Didimo has been deleted successfully"
      />
    </div>
  );
})`
  /*display: grid;
${(props) => props.theme.breakpoints.up('xs')} {
    background-color: orange;
}
${(props) => props.theme.breakpoints.up('sm')} {
    background-color: yellow;
}
${(props) => props.theme.breakpoints.up('md')} {
    background-color: green;
    color: black;
}
${(props) => props.theme.breakpoints.up('lg')} {
    background-color: blue;
    color: white;
}
${(props) => props.theme.breakpoints.up('xl')} {
    background-color: red;
    color: white;
}*/
`;

export default DidimoCard;
