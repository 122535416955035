import React, { useContext, useEffect, useState, useRef } from 'react';
import {
  Button,
  Card,
  CardHeader,
  TableCell,
  Tooltip,
} from '@material-ui/core';
import GenerateDidimosModel from '../../components/DidimosGenerate/GenerateDidimosModel';
import {
  Backdrop,
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  Alert,
  Typography,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import './MyDidimos.css';
import { deleteData, getData, postData } from 'src/helpers/dataFetching';
import { GlobalContext } from '../../context/store';
import FilterModal from '../../components/FilterModel/FilterModal';
import '../../components/DidimosGenerate/GenerateDidimoModal.css';
import DidimoCard from './DidimoCard';
import { format } from 'date-fns';
import DeleteModal from '../../components/DeleteModal';
import CookieBase from 'src/components/CookieBase';
import {
  createSearchParams,
  Link,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import FaceIcon from '@material-ui/icons/Face';
import ErrorSnack from '../../components/ErrorSnack';
import {
  initStatusManager,
  addStatusManager,
  removeStatusManager,
  isUserAuthenticated,
} from '../../context/actions';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
/*import Pagination from './Pagination';*/
import Pagination from '@mui/material/Pagination';
import DidimoGrid from './DidimoGrid';
import { Helmet } from 'react-helmet';
import { DIDIMO_GENERATING_UPDATE_TIME } from 'src/utils/constants';

const MyDidimos = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const statusManager = state?.statusManager;
  const navigate = useNavigate();
  const [isOpenCrateDidmModal, setisOpenCrateDidmModal] = useState(false);
  const [pending_orders, setPending_orders] = useState();
  const [balance, setBalance] = useState();

  // Filters
  const [createdAtFrom, setCreatedAtFrom] = React.useState();
  const [createdAtTo, setCreatedAtTo] = React.useState();
  const [expiresAtFrom, setExpiresAtFrom] = React.useState();
  const [expiresAtTo, setExpiresAtTo] = React.useState();
  const [status, setStatus] = React.useState([]);
  const [isFavorite, setIsFavorite] = React.useState(undefined);
  const [ddmoKey, setDdmoKey] = React.useState('');
  const [sortBy, setSortBy] = React.useState('-created_at');
  const [showSample, setShowSample] = React.useState(false);
  const [parametersLoaded, setParametersLoaded] = React.useState(false);
  // /Filters

  //
  const [currentPageDidimos, setCurrentPageDidimos] = React.useState([]);
  //

  // Checked cards
  const [selectedDidimos, setSelectedDidimos] = React.useState({});
  const [selectedDidimosLen, setSelectedDidimosLen] = React.useState(0);

  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessageOpen, setErrorMessageOpen] = React.useState(false);

  const [totalDidimoListSize, setTotalDidimoListSize] = React.useState('');
  const [totalfilteredSize, setTotalfilteredSize] = React.useState(0);

  const [min_created_at, setMin_created_at] = useState('');
  const [max_created_at, setMax_created_at] = useState('');
  const [min_expires_at, setMin_expires_at] = useState('');
  const [max_expires_at, setMax_expires_at] = useState('');
  const [didimoNextPageUrl, setdidimoNextPageUrl] = useState(null);

  const [ddmoKeyOld, setddmoKeyOld] = React.useState('');
  const [is_favorite, setIs_favorite] = React.useState(false);
  const [metaDataInformation, setMetaInformation] = useState();
  const [removeDidimos, setRemoveDidimos] = useState([]);
  const [isScroll, setisScroll] = useState(false);
  const [toDeleteDdmo, setDelete] = useState([]);
  const [dataLength, setLength] = useState();
  const [generatedKey, setGeneratedKey] = useState();
  const [invalidFilter, setInvalidFilter] = useState(false);
  const [progress, setProgress] = useState('');
  const [versionOptions, setVersionOptions] = useState([]);
  const [customerPortal, setCustomerPortal] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(40);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [showDeleteNotification, setShowDeleteNotification] = useState(false);

  const [localStatusManager, _setLocalStatusManager] = useState(statusManager);
  const localStatusManagerRef = useRef(localStatusManager);
  const setLocalStatusManager = (data) => {
    localStatusManagerRef.current = data;
    _setLocalStatusManager(data);
  };

  /** Status manager - Start */
  useEffect(() => {
    (async () => {
      try {
        if (state.isAuth === false) {
          dispatch(await isUserAuthenticated(navigate, 'welcome'));
        } else {
          let orderBy = searchParams.get('sort_by')
            ? searchParams.get('sort_by')
            : '-created_at';
          axios
            .get(
              `/didimos?order_by=${orderBy}&status=pending&status=processing&page_size=40`
            )
            .then((response) => {
              dispatch(addStatusManager(response.data));
            })
            .catch((error) => {
              // const payload = {
              //   id: item?.id,
              //   type: 'generating',
              //   percent: -1,
              //   status: item?.status,
              // };
              // dispatch(removeStatusManager(payload));
            });
          checkAccountStatus();
          localStorage.setItem('firstTimeLogin', 1);
          const interval = setInterval(() => {
            if (
              window.location.pathname === '/mydidimos' &&
              localStatusManagerRef.current.length > 0
            ) {
              watchDidimoProgressStatus();
            }
          }, 4000);
          if (!localStatusManagerRef.current) {
            clearInterval(interval);
          }
          return () => {
            clearInterval(interval);
          };
          // }
        }
      } catch (err) {
        console.log(err);
      }
    })();
    setLoading(true);
  }, [state?.isAuth]);

  const checkAccountStatus = () => {
    axios
      .get(`accounts/default/status`)
      .then((res) => {
        const response = res.data;
        setPending_orders(response['pending_orders']?.length);
        setBalance(response.balance);
      })
      .catch((error) => {
        console.log('There was an error while checking account status', error);
      });
  };

  const watchDidimoProgressStatus = () => {
    if (localStatusManagerRef.current.length > 0) {
      let orderBy = searchParams.get('sort_by')
        ? searchParams.get('sort_by')
        : '-created_at';

      axios
        .get(
          `/didimos?order_by=${orderBy}&status=pending&status=processing&page_size=40`
        )
        .then((response) => {
          dispatch(addStatusManager(response.data));
          let currentProgressLength = localStatusManagerRef.current.length;
          if (currentProgressLength !== response.data.total_size) {
            fetchCurrentPageDidimos();
          }
        })
        .catch((error) => {
          // const payload = {
          //   id: item.id,
          //   type: 'generating',
          //   percent: -1,
          //   status: item.status,
          // };
          // dispatch(removeStatusManager(payload));
        });
    }
  };

  useEffect(() => {
    // const reversStatusManager = statusManager.reverse();
    if (!statusManager) {
      dispatch(initStatusManager([]));
    } else {
      setLocalStatusManager(statusManager);
      if (!statusManager.length && parametersLoaded) {
        fetchCurrentPageDidimos();
      }
    }
  }, [statusManager]);

  /** Status manager - End */

  useEffect(() => {
    const getNullableDateParam = (paramName) => {
      let value = searchParams.get(paramName) || '';
      return value;
    };

    let currPage = parseInt(searchParams.get('page') || '1');
    setCurrentPage(currPage);
    setDdmoKey(searchParams.get('key'));
    let strIsFavorite = searchParams.get('is_favorite');
    setIsFavorite(strIsFavorite === 'true' ? true : '');
    setCreatedAtFrom(getNullableDateParam('min_created_at'));
    setCreatedAtTo(getNullableDateParam('max_created_at'));
    setExpiresAtFrom(getNullableDateParam('min_expires_at'));
    setExpiresAtTo(getNullableDateParam('max_expires_at'));
    setSortBy(searchParams.get('sort_by') || '-created_at');
    let strShowSample = searchParams.get('show_sample') || 'false';
    setShowSample(strShowSample === 'true');
    var status = searchParams.getAll('status');
    if (!Array.isArray(status)) {
      status = [status];
    }
    setStatus(status || []);
    setParametersLoaded(true);
  }, [searchParams]);

  useEffect(() => {
    if (showSample) {
      axios
        .get(`didimos/demos`, { params: { sort_by: '-created_at' } })
        .then((response) => {
          const data = response.data;
          setTotalDidimoListSize(data?.total_size);
          // setTotalfilteredSize(sortBy === '-created_at' ? 1 : 0);
          setCurrentPageDidimos(data?.didimos);
          setIsLoading(false);
        });
    } else {
      if (parametersLoaded) {
        fetchCurrentPageDidimos();
      }
    }
  }, [
    parametersLoaded,
    currentPage,
    createdAtFrom,
    createdAtTo,
    expiresAtFrom,
    expiresAtTo,
    status,
    isFavorite,
    sortBy,
    ddmoKey,
    showSample,
  ]);

  useEffect(() => {
    let newSelectedDidimos = {};
    for (const didimo of currentPageDidimos) {
      newSelectedDidimos[didimo.key] = false;
    }
    setSelectedDidimos(newSelectedDidimos);
  }, [currentPageDidimos]);

  useEffect(() => {
    setSelectedDidimosLen(getSelectedDidimoKeys().length);
  }, [selectedDidimos]);

  const fetchCurrentPageDidimos = () => {
    // Everytime a query param changes, this will be updated
    const originalParametersData = {
      key: ddmoKey,
      is_favorite: isFavorite,
      min_created_at: createdAtFrom,
      max_created_at: createdAtTo,
      min_expires_at: expiresAtFrom,
      max_expires_at: expiresAtTo,
      order_by: sortBy,
      status: status,
      page: currentPage,
      page_size: postsPerPage,
    };
    let parametersData = {};
    for (const item of Object.entries(originalParametersData)) {
      if (item[1] !== undefined && item[1] !== null && item[1] !== '') {
        parametersData[item[0]] = item[1];
      }
    }

    axios
      .get(`didimos`, {
        params: parametersData,
        paramsSerializer: (params) => {
          return createSearchParams(params);
        },
      })
      .then((response) => {
        if (
          response.data?.total_size === 0 &&
          localStorage.getItem('firstTimeLogin') === '1'
        ) {
          axios
            .get(`didimos/demos`, { params: { sort_by: '-created_at' } })
            .then((response) => {
              const data = response.data;
              setTotalDidimoListSize(data?.total_size);
              setCurrentPageDidimos(data?.didimos);
              setShowSample(true);
            });
        } else {
          const data = response.data;
          setTotalDidimoListSize(data?.total_size);
          setCurrentPageDidimos(data?.didimos);
        }
        setLoading(false);
      });
  };

  const updateUrlFilters = (params, includeEmpty) => {
    const paramMapping = {
      createdAtFrom: 'min_created_at',
      createdAtTo: 'max_created_at',
      expiresAtFrom: 'min_expires_at',
      expiresAtTo: 'max_expires_at',
      status: 'status',
      isFavorite: 'is_favorite',
      key: 'key',
      sortBy: 'sort_by',
      showDemo: 'show_sample',
      page: 'page',
      currentPage: 'page',
    };

    let urlParams = {};
    for (const item of Object.entries(params)) {
      if (item[1] || includeEmpty) {
        if (!paramMapping[item[0]]) alert(item[0]);
        urlParams[paramMapping[item[0]]] = item[1];
      }
    }
    navigate({
      pathname: '/mydidimos',
      search: `?${createSearchParams(urlParams)}`,
    });
  };

  const handleApplyFilter = (filters) => {
    updateUrlFilters(filters);
    //AppliedFilter();
    setOpen(false);
    setisFilterApplied(true);
  };

  const handleCheckSample = (e) => {
    handleApplyFilter({
      isFavorite: isFavorite,
      key: ddmoKey,
      createdAtFrom: createdAtFrom,
      createdAtTo: createdAtTo,
      expiresAtFrom: expiresAtFrom,
      expiresAtTo: expiresAtTo,
      status: status,
      sortBy: sortBy,
      showDemo: e.target.checked,
      page: currentPage,
    });
    setShowSample(e.target.checked);
    localStorage.setItem('firstTimeLogin', 0);
  };

  const [isFilterApplied, setisFilterApplied] = useState(false);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setStatus(typeof value === 'string' ? value.split(',') : value);
  };

  const handleFilterModel = () => {
    setOpen(true);
  };

  const handleCloseFilter = () => {
    /*const params = window.location.search.substr(1);
    if (!params) {
      setddmoKeyOld('');
      setMin_expires_at('');
      setExpiresAtFrom(null);

      setMax_expires_at('');
      setExpiresAtTo(null);
      setMin_created_at('');
      setCreatedAtFrom(null);
      setMax_created_at('');
      setCreatedAtTo(null);
      setStatus([]);
    } else {
      if (!params.includes('key=')) {
        setddmoKeyOld('');
      }
      if (!params.includes('min_expires_at=')) {
        setMin_expires_at('');
        setExpiresAtFrom(null);
      }
      if (!params.includes('max_expires_at=')) {
        setMax_expires_at('');
        setExpiresAtTo(null);
      }
      if (!params.includes('min_created_at=')) {
        setMin_created_at('');
        setCreatedAtFrom(null);
      }
      if (!params.includes('max_created_at=')) {
        setMax_created_at('');
        setCreatedAtTo(null);
      }
      if (!params.includes('status=')) {
        setStatus([]);
      }
      if (window.location.search === '') {
        setTotalfilteredSize(0);
        setisFilterApplied(false);
      } else {
        setisFilterApplied(true);
      }
    }*/
    setOpen(false);
  };

  const handleClearFilter = () => {
    setddmoKeyOld(null);
    setStatus([]);
    setCreatedAtFrom(null);
    setCreatedAtTo(null);
    setExpiresAtFrom(null);
    setExpiresAtTo(null);
    setIsFavorite(false);
  };

  const getMetadataStructureHandler = async (generatorUUID) => {
    const accessToken = state?.user?.accessToken;
    const metaInformation = await getData(
      `didimos/${generatorUUID}`,
      accessToken
    );
    return metaInformation;
  };

  const handleFilterChange = (type, value) => {
    if (type === 'key') {
      setddmoKeyOld(value);
    } else if (type === 'is_favorite') {
      setIs_favorite(value);
    }
  };

  const [isSelectAll, setIsSelectAll] = useState();

  const isAnyDidimoSelected = () => {
    return Object.entries(selectedDidimos).some((sd) => sd[1] === true);
  };

  const getSelectedDidimoKeys = () => {
    return Object.entries(selectedDidimos)
      .filter((sd) => sd[1] === true)
      .map((sd) => sd[0]);
  };

  const handleCheckedAll = () => {
    let isAnyDdmoSelected = isAnyDidimoSelected();
    let selectedDidimosTmp = { ...selectedDidimos };
    for (let key of Object.keys(selectedDidimosTmp)) {
      selectedDidimosTmp[key] = !isAnyDdmoSelected;
    }

    setSelectedDidimos(selectedDidimosTmp);

    let toDelete = [];
    if (toDeleteDdmo.length === 0) {
      toDelete = currentPageDidimos
        .filter((f) => f.is_sample !== 1)
        .map((d) => d.key);
    }
    setDelete(toDelete);
    setIsSelectAll(toDelete.length === 0);
  };

  const onCheck = (key) => {
    let selectedDidimosTmp = { ...selectedDidimos };
    selectedDidimosTmp[key] = true;
    setSelectedDidimos(selectedDidimosTmp);

    let toDeleteData = toDeleteDdmo;
    toDeleteData.push(key);
    setLength(toDeleteData.length);
    setDelete(toDeleteData);
  };

  const onUncheck = (key) => {
    let selectedDidimosTmp = { ...selectedDidimos };
    selectedDidimosTmp[key] = false;
    setSelectedDidimos(selectedDidimosTmp);
    let toDelete = toDeleteDdmo;
    toDelete.splice(toDelete.indexOf(key), 1);
    setLength(toDelete.length);
    setDelete(toDelete);
  };

  const handleCreatedDateChange = (date) => {
    setCreatedAtFrom(date);

    setMin_created_at(format(new Date(date), 'yyyy/MM/dd'));
  };

  const handleCreatedDateChangeTo = (date) => {
    setCreatedAtTo(date);
    setMax_created_at(format(new Date(date), 'yyyy/MM/dd'));
  };

  const handleExpireDateChange = (date) => {
    setExpiresAtFrom(date);
    setMin_expires_at(format(new Date(date), 'yyyy/MM/dd'));
  };

  const handleExpireDateChangeTo = (date) => {
    setExpiresAtTo(date);
    setMax_expires_at(format(new Date(date), 'yyyy/MM/dd'));
  };

  const handleCreateModal = () => {
    axios
      .get(`/didimos/releases`, {
        headers: { Authorization: `Bearer ${state?.user?.accessToken}` },
      })
      .then((res) => {
        setVersionOptions(res.data);
      });

    axios
      .get(`/platforms/customer_portal`, {
        headers: { Authorization: `Bearer ${state?.user?.accessToken}` },
      })
      .then((res) => {
        setCustomerPortal(res.data);
      });

    setisOpenCrateDidmModal(!isOpenCrateDidmModal);
  };

  const handleScroll = () => {
    if (
      didimoNextPageUrl !== null &&
      window.location.search == '' &&
      !showSample
    ) {
      return getData(didimoNextPageUrl, state?.user?.accessToken).then(
        (response) => {
          const didimo = response?.didimos;
          setCurrentPageDidimos(didimo);
          setLoading(false);
          setTotalDidimoListSize(response?.total_size);
          setdidimoNextPageUrl(response?.__links.next);
        }
      );
    }
  };

  const handleLoadMore = () => {
    const nextSetUrl = didimoNextPageUrl
      ? didimoNextPageUrl
      : 'didimos/actions/stream';
    setIsLoading(true);
    getData(nextSetUrl, true, state?.user?.accessToken).then((response) => {
      setIsLoading(false);
      setTotalDidimoListSize(response?.total_size);
      setCurrentPageDidimos(response?.didimos);
      setLoading(false);
      setdidimoNextPageUrl(response?.__links.next);
      if (response?.total_size > 0) {
        setisScroll(true);
        handleScroll();
      }
    });
  };

  const refreshFirstPage = () => {
    if (currentPage === 1) {
      if (showSample) {
        axios
          .get(`didimos/demos`, { params: { sort_by: '-created_at' } })
          .then((response) => {
            const data = response.data;
            setTotalDidimoListSize(data?.total_size);
            // setTotalfilteredSize(sortBy === '-created_at' ? 1 : 0);
            setCurrentPageDidimos(data?.didimos);
            setLoading(false);
          });
      } else {
        setTimeout(function () {
          fetchCurrentPageDidimos(); // for only currentPage == 1
        }, 9000);
      }
    } else {
      paginate(1);
    }
  };

  const handleDelete = () => {
    try {
      toDeleteDdmo.forEach((key) => {
        deleteData(`didimos/${key}`, state?.user?.accessToken)
          .then(() => {
            setDelete([]);
            setRemoveDidimos([]);
            setShowDeleteNotification(true);
            fetchCurrentPageDidimos();
          })

          .catch((err) => {
            setErrorMessageOpen(true);
          });
      });
      //handleLoadMore();
    } catch (err) {
      console.log(err);
    }
  };

  function LinearProgressWithLabel(props) {
    return (
      <div>
        <div className="progress-title-container">
          <div className="progress-title-left">
            <div>
              <div className="txt-ellipse1 txt-progress-title">
                {props.title}
              </div>
              <div className="txt-ellipse1 txt-progress-description">
                {`[ ${props.description} ]`}
              </div>
            </div>
            <div>
              <div className="txt-ellipse1 txt-progress-description">
                {`${props.value > 0 ? props.value : 0}%`}
              </div>
            </div>
          </div>
        </div>
        <div className="progress-bar-body">
          <div
            className="progressing"
            style={{ maxWidth: `${props.value > 0 ? props.value : 0}%` }}
          />
        </div>
      </div>
    );
  }

  const onUpdateDidimo = (key, item) => {
    let didimos = [...currentPageDidimos];
    const didimoPos = currentPageDidimos.findIndex((ddm) => ddm.key === key);
    if (didimoPos >= 0) {
      didimos[didimoPos] = item;
    }
    if (didimos.length !== 0) {
      setCurrentPageDidimos(didimos);
      setLoading(false);
    }
    //handleLoadMore();
    setGeneratedKey(key);
  };

  const onFavourite = (ddmKey) => {
    let switchKey = 'favorite';
    let didimos = [...currentPageDidimos];
    const didimoPos = didimos.findIndex((ddm) => ddm.key === ddmKey);
    if (didimos[didimoPos].is_favorite === true) {
      switchKey = 'unfavorite';
    }
    axios
      .post('didimos/' + ddmKey + '/actions/' + switchKey, {})
      .then((response) => {
        didimos[didimoPos] = response.data;
        /*if (switchKey === 'unfavorite') {
          didimos[didimoPos] = response.data;
        } else {
          didimos[didimoPos].is_favorite = true;
        }*/
        setCurrentPageDidimos(didimos);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const currentPosts = currentPageDidimos;

  const paginate = (pageNumber) => {
    handleApplyFilter({
      isFavorite: isFavorite,
      key: ddmoKey,
      createdAtFrom: createdAtFrom,
      createdAtTo: createdAtTo,
      expiresAtFrom: expiresAtFrom,
      expiresAtTo: expiresAtTo,
      status: status,
      sortBy: sortBy,
      showDemo: showSample,
      page: pageNumber,
    });
    //setCurrentPage(pageNumber);
    //retrieveDataByPage(pageNumber);
  };

  return (
    <>
      <Helmet>
        <title>My didimos | Didimo</title>
      </Helmet>
      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={'myDidimoContainer'}>
        <ToastContainer />
        <Box sx={{ py: 0, px: 2 }} className={'filter-alert'}>
          {invalidFilter && (
            <Alert severity="error">
              Sorry there is problem with your filter!
            </Alert>
          )}
        </Box>
        <Box sx={{ py: 2, px: 2 }}>
          {/*progress ? (
          <Alert severity="error" style={{ borderColor: 'red' }}>
            {progress}
          </Alert>
        ) : null*/}
          {pending_orders > 0 && (
            <Alert
              severity="warning"
              style={{
                border: '1px solid #ff9800',
                backgroundColor: 'white',
                marginBottom: '10px',
              }}
            >
              You have pending invoices. Please visit{' '}
              <Link to="/orders">Orders</Link> to proceed with the payment.
            </Alert>
          )}
          <Card>
            <CardHeader
              className={'didimos-header'}
              title={`My didimos (${totalDidimoListSize})`}
              action={
                <>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ marginLeft: '10px' }}
                    type="submit"
                    size="small"
                    onClick={handleCheckedAll}
                    disabled={showSample}
                  >
                    {' '}
                    {isAnyDidimoSelected() ? 'Clear Selection' : 'Select All'}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ marginLeft: '10px' }}
                    type="submit"
                    size="small"
                    disabled={selectedDidimosLen === 0}
                    onClick={(e) => {
                      setRemoveDidimos(getSelectedDidimoKeys());
                    }}
                  >
                    {' '}
                    Delete {selectedDidimosLen > 0 && `(${selectedDidimosLen})`}
                  </Button>
                </>
              }
            />

            <div className="header_content">
              <Card className={'DemoConatainer'}>
                <div className="button_container">
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={handleCreateModal}
                    sx={{ ml: 2 }}
                    disabled={!balance || balance < 1}
                  >
                    Generate
                  </Button>
                  {(!balance || balance) < 1 && (
                    <span className="generate-info-icon">
                      <Tooltip title="Not enough points to generate didimo">
                        <InfoIcon />
                      </Tooltip>
                    </span>
                  )}
                  <span className="show_demos_title">Show Demos</span>
                </div>
                <CardHeader
                  // title={'Show Demos'}
                  action={
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <Checkbox
                        checked={showSample}
                        color="default"
                        inputProps={{
                          'aria-label': 'checkbox with default color',
                        }}
                        onChange={handleCheckSample}
                      />
                      {!showSample && (
                        <FilterModal
                          open={open}
                          ddmoKey={ddmoKey}
                          isFavorite={isFavorite}
                          createdAtFrom={createdAtFrom}
                          createdAtTo={createdAtTo}
                          expiresAtFrom={expiresAtFrom}
                          expiresAtTo={expiresAtTo}
                          status={status}
                          sortBy={sortBy}
                          totalfilteredSize={totalfilteredSize}
                          setSortOrder={setSortBy}
                          handleFilterChange={handleFilterChange}
                          handleCreatedDateChange={handleCreatedDateChange}
                          handleCreatedDateChangeTo={handleCreatedDateChangeTo}
                          handleExpireDateChange={handleExpireDateChange}
                          handleExpireDateChangeTo={handleExpireDateChangeTo}
                          handleChange={handleChange}
                          handleCloseFilter={handleCloseFilter}
                          handleClearFilter={handleClearFilter}
                          handleApplyFilter={handleApplyFilter}
                          handleFilterModel={handleFilterModel}
                        />
                      )}
                    </div>
                  }
                />
              </Card>
            </div>

            {/*<DidimoGrid
            didimos={currentPageDidimos}
          >
            Test
            </DidimoGrid>*/}

            {localStatusManagerRef.current &&
            !!localStatusManagerRef.current.length ? (
              <div className="progress-body">
                {localStatusManagerRef?.current?.map((c, key) => {
                  return (
                    <div className={'progress-item'} key={c.id}>
                      <LinearProgressWithLabel
                        value={c.percent}
                        title={c.status}
                        description={c.id}
                      />
                    </div>
                  );
                })}
              </div>
            ) : null}

            <Grid className="data_content">
              {totalDidimoListSize === 0 && !isLoading && (
                <div style={{ textAlign: 'center', marginBottom: '8px' }}>
                  <FaceIcon style={{ fontSize: 200 }} />
                  <p style={{ fontWeight: 'bold', fontSize: 20 }}>
                    There are no didimos in this account.
                  </p>
                </div>
              )}
              <DidimoCard
                isScroll={isScroll}
                didimoNextPageUrl={didimoNextPageUrl}
                handleScroll={handleScroll}
                AllDidimos={currentPosts}
                totalDidimoListSize={totalDidimoListSize}
                setDelete={setDelete}
                toDeleteDdmo={toDeleteDdmo}
                showSample={showSample}
                accessToken={state?.user?.accessToken}
                metaDataInformation={metaDataInformation}
                getMetadataStructureHandler={getMetadataStructureHandler}
                handleLoadMore={handleLoadMore}
                onCheck={onCheck}
                onUncheck={onUncheck}
                dataLength={dataLength}
                generatedKey={generatedKey}
                onFavourite={onFavourite}
                setProgress={setProgress}
                onUpdateDidimo={onUpdateDidimo}
                selectedDidimos={selectedDidimos}
                fetchCurrentPageDidimos={fetchCurrentPageDidimos}
              />
            </Grid>

            <div className="didimos-pagination">
              <Pagination
                count={Math.ceil(totalDidimoListSize / postsPerPage)}
                page={currentPage}
                showFirstButton
                showLastButton
                onChange={(e, page) => paginate(page)}
              />
            </div>
          </Card>
        </Box>
        <GenerateDidimosModel
          isOpen={isOpenCrateDidmModal}
          onCloseModal={handleCreateModal}
          setPending_orders={setPending_orders}
          handleLoadMore={refreshFirstPage}
          versionOptions={versionOptions}
          customerPortal={customerPortal}
        />
        <Backdrop open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <CookieBase />

        <DeleteModal
          isOpen={removeDidimos.length > 0}
          onCloseModal={() => {
            setRemoveDidimos([]);
          }}
          handleDelete={handleDelete}
        />

        <ErrorSnack
          open={errorMessageOpen}
          onClose={(e) => {
            setErrorMessageOpen(false);
          }}
          message={'An error occurred while processing your order.'}
        />
        <ErrorSnack
          open={showDeleteNotification}
          onClose={(e) => {
            setShowDeleteNotification(false);
          }}
          message="Didimo has been deleted successfully"
        />
      </div>
    </>
  );
};
export default MyDidimos;
