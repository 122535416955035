import React, { useContext, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Card, CardHeader, CardContent } from '@mui/material';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/styles';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router';
import { GlobalContext } from '../../context/store';
import { encode as base64_encode } from 'base-64';
import { useState } from 'react';
import Iframe from 'react-iframe';
import { Backdrop, CircularProgress } from '@material-ui/core';
import './PreviewDidimo.css';
import PersonIcon from '@mui/icons-material/Person';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import LoopIcon from '@mui/icons-material/Loop';
import classNames from 'classnames';

let didimo_api_root = process.env.REACT_APP_API_ROOT;
const useStyles = makeStyles(() => ({
  media: {
    cursor: 'pointer',
    height: 225,
    maxWidth: '190px',
    position: 'relative',
    boxShadow:
      '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    margin: '10px',
    width: '100%',
  },
  btn: {
    marginRight: '5px',
  },
  backArrow: {
    color: '#2a0651',
    fontSize: '23px',
    cursor: 'pointer',
    verticalAlign: 'middle',
    marginRight: '10px',
  },
}));

const PreviewDidimo = () => {
  const { state } = useContext(GlobalContext);
  const classes = useStyles();

  const { key } = useParams();
  const [didimoDetails, setDidimoDetails] = useState({});
  const [avatarStructure, setAvatarStructure] = useState(null);
  const navigate = useNavigate();
  const [url, setUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const iFrameRef = useRef();
  const [didimoFrame, setDidimoFrame] = useState(null);
  const [ratings, setRatings] = useState(0);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    loadDidimoDetails();
    Preview();
  }, []);

  useEffect(async () => {
    let { DidimoFrame } = await import(
      /* webpackIgnore: true */ process.env.REACT_APP_DIDIMO_FRAME
    );
    setDidimoFrame(new DidimoFrame(iFrameRef.current.contentWindow));
  }, [iFrameRef]);

  const handleChangeRatings = (rating, comments) => {
    setRatings(rating);

    if (didimoDetails?.data.didimo_feedback?.length > 0) {
      putFeedback(key, rating, comments);
    } else {
      postFeedback(key, rating, comments);
    }
  };

  const setHeadCamera = async () => {
    didimoFrame.setCamera('head_camera');
  };
  const setBodyCamera = async () => {
    didimoFrame.setCamera('body_camera');
  };

  const postFeedback = async (didimoUUID, starRatingValue, ratingComment) => {
    var form = new FormData();
    form.append('rating', starRatingValue);
    form.append('comment', ratingComment);

    let data = JSON.stringify({
      rating: starRatingValue,
      comment: ratingComment,
    });

    axios({
      method: 'post',
      url: `${didimo_api_root}didimos/${didimoUUID}/actions/feedback`,
      data: data,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(function (response) {
        loadDidimoDetails();
      })
      .catch(function (err) {
        console.log('err', err);
      });
  };

  const putFeedback = async (didimoUUID, starRatingValue, ratingComment) => {
    var form = new FormData();
    form.append('rating', starRatingValue);
    form.append('comment', ratingComment);

    let data = JSON.stringify({
      rating: starRatingValue,
      comment: ratingComment,
    });

    const feedbackUUID = didimoDetails?.data?.didimo_feedback[0]?.uuid;

    axios({
      method: 'put',
      url: `${didimo_api_root}didimos/${didimoUUID}/actions/feedback/${feedbackUUID}`,
      data: data,
    })
      .then(function (response) {})
      .catch(function (err) {
        console.log('err', err);
      });
  };

  const loadDidimoDetails = () => {
    axios.get(`didimos/${key}`).then((response) => {
      setAvatarStructure(
        response.data['meta_data']?.find(
          (meta) => meta['name'] === 'avatar_structure'
        )['value']
      );

      setDidimoDetails(response);

      if (response?.data['didimo_feedback'][0]?.rating) {
        setRatings(response?.data['didimo_feedback'][0]?.rating);
      }
    });
  };

  const Preview = () => {
    axios
      .get('didimos/' + key + '/results/gltf', {
        headers: {
          Authorization: `Bearer ${state.user?.accessToken}`,
          'didimo-follow-uri': 'false',
          'content-type': 'application/json',
        },
      })
      .then(async (response) => {
        let encodedURL =
          process.env.REACT_APP_PREVIEW +
          '#didimo=' +
          base64_encode(response.data.uri);
        await setUrl(encodedURL);
        setIsLoading(false);
      });
  };

  const onClickToggle = (e) => {
    e.preventDefault();
    setToggle(!toggle);
  };

  return (
    <>
      <Helmet>
        <title>didimo Preview | Didimo</title>
      </Helmet>
      <Backdrop open={isLoading}>
        <CircularProgress />
      </Backdrop>
      <Box
        className={'didimo-detail-container'}
        sx={{
          backgroundColor: 'transparent',
          py: 3,
          px: 3,
        }}
        style={{ height: '100%' }}
      >
        <Card style={{ height: '100%' }}>
          <CardHeader
            title={
              <div>
                <ArrowBackIcon
                  className={classes.backArrow}
                  onClick={() => navigate(-1)}
                />
                Didimo Preview
                {key ? ' - ' + key : 'N/A'}
              </div>
            }
          />
          <CardContent style={{ height: '95%' }} className={'viewer-container'}>
            <div>
              <div className="camera-face" title="Head Camera">
                {(avatarStructure === 'full-body' && (
                  <PersonIcon onClick={setHeadCamera} />
                )) || <LoopIcon onClick={setHeadCamera} />}
              </div>
              {avatarStructure === 'full-body' && (
                <div className="camera-body" title="Body Camera">
                  <AccessibilityIcon onClick={setBodyCamera} />
                </div>
              )}
            </div>
            <div className="rating-show-box">
              <a class="btn-toggle" onClick={onClickToggle}>
                {ratings > 0 && <div id="RateNu">{ratings}</div>}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    x="2"
                    y="3"
                    width="19"
                    height="19"
                    rx="3"
                    fill="white"
                  ></rect>
                  <rect width="24" height="24" rx="2" fill="#2A0651"></rect>
                  <path
                    d="M17 19.1375L14.925 20.3875C14.8333 20.4458 14.7375 20.4708 14.6375 20.4625C14.5375 20.4542 14.45 20.4208 14.375 20.3625C14.3 20.3042 14.2417 20.2313 14.2 20.144C14.1583 20.0563 14.15 19.9583 14.175 19.85L14.725 17.4875L12.8875 15.9C12.8042 15.825 12.7522 15.7395 12.7315 15.6435C12.7105 15.5478 12.7167 15.4542 12.75 15.3625C12.7833 15.2708 12.8333 15.1958 12.9 15.1375C12.9667 15.0792 13.0583 15.0417 13.175 15.025L15.6 14.8125L16.5375 12.5875C16.5792 12.4875 16.6438 12.4125 16.7315 12.3625C16.8188 12.3125 16.9083 12.2875 17 12.2875C17.0917 12.2875 17.1813 12.3125 17.269 12.3625C17.3563 12.4125 17.4208 12.4875 17.4625 12.5875L18.4 14.8125L20.825 15.025C20.9417 15.0417 21.0333 15.0792 21.1 15.1375C21.1667 15.1958 21.2167 15.2708 21.25 15.3625C21.2833 15.4542 21.2897 15.5478 21.269 15.6435C21.248 15.7395 21.1958 15.825 21.1125 15.9L19.275 17.4875L19.825 19.85C19.85 19.9583 19.8417 20.0563 19.8 20.144C19.7583 20.2313 19.7 20.3042 19.625 20.3625C19.55 20.4208 19.4625 20.4542 19.3625 20.4625C19.2625 20.4708 19.1667 20.4458 19.075 20.3875L17 19.1375Z"
                    fill="#ffff00"
                  ></path>
                  <path
                    d="M19 5L4 20"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                  ></path>
                  <path
                    id="rateDash"
                    style={ratings == 0 ? {} : { display: 'none' }}
                    d="M9.25736 7.74265H4"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                  ></path>{' '}
                </svg>
              </a>

              <div
                class={classNames(
                  'rating-dropdown2 text-center',
                  { 'rating-dropdown2-show': toggle },
                  { 'rating-dropdown2-none': !toggle }
                )}
              >
                <div className="rating-head">
                  <h5>Rating your didimo</h5>
                </div>
                <div className="rating-list">
                  <ul class="list-inline">
                    <li
                      style={ratings >= 1 ? { color: '#ff0' } : {}}
                      onClick={() => handleChangeRatings(1, '', 'one')}
                      className="list-inline-item"
                    >
                      <i className="fa-solid fa-star" id="one"></i>
                    </li>
                    <li
                      style={ratings >= 2 ? { color: '#ff0' } : {}}
                      onClick={() => handleChangeRatings(2, '', 'two')}
                      className="list-inline-item"
                    >
                      <i className="fa-solid fa-star" id="two"></i>
                    </li>
                    <li
                      style={ratings >= 3 ? { color: '#ff0' } : {}}
                      onClick={() => handleChangeRatings(3, '', 'three')}
                      className="list-inline-item"
                    >
                      <i className="fa-solid fa-star" id="three"></i>
                    </li>
                    <li
                      style={ratings >= 4 ? { color: '#ff0' } : {}}
                      onClick={() => handleChangeRatings(4, '', 'four')}
                      className="list-inline-item"
                    >
                      <i className="fa-solid fa-star" id="four"></i>
                    </li>
                    <li
                      style={ratings >= 5 ? { color: '#ff0' } : {}}
                      onClick={() => handleChangeRatings(5, '', 'five')}
                      className="list-inline-item"
                    >
                      <i className="fa-solid fa-star" id="five"></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <iframe
              src={url}
              //url={url}
              ref={iFrameRef}
              width="100%"
              height="100%"
              display="block"
              position="relative"
            />
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default PreviewDidimo;
