import React, { useContext, useState } from 'react';
import { Box, Card, CardContent, CardHeader, CardMedia } from '@mui/material';
import { deleteData, getData } from '../../helpers/dataFetching';
import { useParams } from 'react-router';
import { GlobalContext } from '../../context/store';
import { Button, CircularProgress } from '@material-ui/core';
import './didimoDetails.css';
import EditDidimoModal from '../EditDidimoModal';
import DeleteModal from '../DeleteModal';
import Version_2_5_0 from './Config/Version_2_5_0';
import Version_2_5_6 from './Config/Version_2_5_6';
import Version_2_5_7 from './Config/Version_2_5_7';
import Version_2_5_9 from './Config/Version_2_5_9';
import Version_2_5_10 from './Config/Version_2_5_10';
import Version_2_5_12 from './Config/Version_2_5_12';
import Version_default from './Config/Version_default';
import fbx from './fbx.png';
import gltf from './gltf.png';
import axios from 'axios';
import { makeStyles } from '@material-ui/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { formatDate, formatPoints } from '../Helpers/formaters';
import { Helmet } from 'react-helmet';
import ErrorSnack from '../ErrorSnack';
import './Config/DidimoGenerateVersions.css';

const useStyles = makeStyles(() => ({
  media: {
    cursor: 'pointer',
    height: 225, //300,
    maxWidth: '190px',
    position: 'relative',
    boxShadow:
      '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    margin: '10px',
    width: '100%',
  },
  btn: {
    marginRight: '5px',
  },
  backArrow: {
    color: '#2a0651',
    fontSize: '23px',
    cursor: 'pointer',
    verticalAlign: 'middle',
    marginRight: '10px',
  },
}));
const DidimoDetails = () => {
  const classes = useStyles();
  const navigator = useNavigate();

  const { state } = useContext(GlobalContext);
  const { key } = useParams();
  const [metaDataInformation, setMetaInformation] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [metaDataInfo, setDdmMetaInfo] = useState();
  const [hasData, setHasData] = useState(false);
  const [ddmMetaAPIVersion, setDdmMetaAPIVersion] = React.useState(null);
  const [ddmMetaPackage, setDdmMetaPackage] = React.useState(null);
  const [metadataStructure, setMetadataStructure] = React.useState(null);
  const [transferFormats, setTransferFormats] = React.useState([]);
  const didimoType = localStorage.getItem('didimoType');
  const [isDeleteModal, setisDeleteModal] = useState(false);
  const [isEditModal, setisEditModal] = useState(false);
  const [isDemo, setIsDemo] = useState(false);
  const [didimoImage, setDidimoImage] = useState();
  const [showDeleteNotification, setShowDeleteNotification] = useState(false);
  const navigate = useNavigate();
  const [customerPortal, setCustomerPortal] = useState([]);
  const [ddmDetailsComponent, setDDMDetailsComponent] = useState('');

  React.useEffect(() => {
    //getMetaInformation();

    GetImage();
    if (!window.location.hash) {
      axios
        .get(`/platforms/customer_portal`, {
          headers: { Authorization: `Bearer ${state?.user?.accessToken}` },
        })
        .then((res) => {
          setCustomerPortal(res.data);
          if (didimoType == null || didimoType === 'true') {
            callDefaultApi('/didimos/demos', res.data);
          } else {
            callDefaultApi('/didimos/actions/stream', res.data);
          }
        });
    }
  }, []);

  const HandleMyDidimo = () => {
    navigator('/mydidimos', { replace: true });
  };

  const GetImage = () => {
    getData('/didimos/' + key + '/artifacts/front_png')
      .then((response) => {
        setDidimoImage(response.uri);
      })
      .catch((err) => {
        console.log('Error while Featching Image!');
      });
  };

  const getMetaInformation = async () => {
    const AllmetaInformation = await getData('didimos/metadata');
    setMetaInformation(AllmetaInformation);
  };

  const getGeneratorUUID = (metadata) => {
    const generatorMeta = metadata?.meta_data.find(
      (item) => item.name === 'generator_uuid'
    );
    return generatorMeta ? generatorMeta.value : null;
  };

  const getMetadataStructureHandler = async (generatorUUID) => {
    // const accessToken = state.user?.accessToken;
    // const metaInformation = await getData(
    //   `dgps/${generatorUUID}/metadata`,
    //   accessToken
    // );
    // return metaInformation;
  };

  const callDefaultApi = (URL, customerPortalData) => {
    try {
      setIsLoading(true);
      getData(URL)
        .then((response) => {
          getData(`didimos/${key}`, state.user.accessToken)
            .then(async (response) => {
              const generatorUUID = getGeneratorUUID(response);
              setMetadataStructure(metaDataInformation);

              let APIVersionData = response?.meta_data.find(
                (f) => f.name === 'api'
              );
              let MetaPackageData = response?.meta_data.find(
                (f) => f.name === 'generator'
              );
              const isDdmoDemo =
                response?.meta_data.find((f) => f.name === 'is_sample')
                  ?.value === 'true';
              setDdmMetaAPIVersion(
                APIVersionData !== undefined ? APIVersionData['value'] : 'N/A'
              );
              setDdmMetaPackage(
                MetaPackageData !== undefined ? MetaPackageData['value'] : 'N/A'
              );
              setDdmMetaInfo(response);
              setHasData(true);
              setIsDemo(isDdmoDemo);
              let transferFormats = [];

              if (MetaPackageData && MetaPackageData['value']) {
                if (customerPortalData?.versions?.length > 0) {
                  let version = process.env.REACT_APP_VERSION
                    ? process.env.REACT_APP_VERSION
                    : '2.5.0';
                  const versionData = customerPortalData.versions.find(
                    (c) => c.code === version
                  );
                  let dgpCompatibilityRules = [];
                  if (versionData) {
                    dgpCompatibilityRules = versionData.dgp_compatibility_rules;
                  }
                  for (var i = 0; i < dgpCompatibilityRules.length; i++) {
                    let regTester = new RegExp(
                      dgpCompatibilityRules[i].pattern
                    );
                    let matchRegEx = regTester.test(MetaPackageData['value']);

                    if (matchRegEx === true) {
                      setDDMDetailsComponent(
                        dgpCompatibilityRules[i].settings
                          .didimo_details_component
                      );
                      break;
                    }
                  }
                }
              }

              if (response === undefined) {
                setIsLoading(false);
                setHasData(false);
              } else {
                response?.transfer_formats.forEach((item) => {
                  transferFormats.push(item.name);
                });
                setTransferFormats(transferFormats);
                setIsLoading(false);
              }
            })
            .catch((err) => {
              setIsLoading(false);
              setHasData(false);
            });
        })
        .catch((err) => {
          setHasData(false);
          setIsLoading(false);
        });
    } catch (e) {
      setIsLoading(false);
      setHasData(false);
    }
  };

  const isEnableElement = (group, element) => {
    let headOnly = metaDataInfo.meta_data.find(
      (f) => f.name === 'avatar_structure'
    );

    let metaInfo = metaDataInfo.meta_data.find((f) => f.name === group);
    if (!metaInfo) {
      if (element !== 'photo') {
        metaInfo = metaDataInfo?.meta_data?.find((f) => f.name === element);
      }
      if (!metaInfo) {
        if (group === 'input_type') {
          if (metaDataInfo?.input_type === element) {
            return true;
          }
        } else if (group === 'transfer_formats') {
          return metaDataInfo?.transfer_formats?.find(
            (f) => f.name === element
          );
        }
      }
    }
    if (metaInfo) {
      if (
        group === 'gender' &&
        element === 'auto' &&
        !metaInfo.value.includes(';')
      ) {
        const genderArray = ['male', 'female'];
        return !genderArray.includes(metaInfo.value);
      } else if (
        group === 'garment' &&
        element === 'none' &&
        !metaInfo.value.includes(';')
      ) {
        const garmentArray = ['casual', 'sporty', 'business'];
        return !garmentArray.includes(metaInfo.value);
      } else if (
        group === 'hair' &&
        element === 'none' &&
        !metaInfo.value.includes(';')
      ) {
        const hairArray = [
          'hair_001',
          'hair_002',
          'hair_003',
          'hair_004',
          'hair_005',
          'hair_006',
          'hair_007',
          'hair_008',
          'hair_009',
          'hair_010',
          'hair_011',
          'baseball_cap',
        ];
        return !hairArray.includes(metaInfo.value);
      } else if (group === 'animation' && !metaInfo.value.includes(';')) {
        return metaInfo.name === element && metaInfo.value === 'true';
      } else if (metaInfo.value.includes(';')) {
        let allElements = metaInfo.value.split(';');
        return allElements.includes(element);
      } else if (
        group === 'max_texture_dimension' &&
        element === metaInfo.value
      ) {
        return true;
      } else if (group === 'body_pose') {
        if (headOnly.value === 'head-only') {
          return false;
        } else {
          return metaInfo.value === element;
        }
      } else {
        return metaInfo.value === element;
      }
    }
    return false;
  };

  const renderDidimoElements = () => {
    switch (ddmDetailsComponent) {
      case 'Version_2_5_0':
      case 'Version_2_5_1':
      case 'Version_2_5_2':
      case 'Version_2_5_3':
      case 'Version_2_5_4':
      case 'Version_2_5_5':
        return <Version_2_5_0 isEnableElement={isEnableElement} />;
      case 'Version_2_5_6':
        return <Version_2_5_6 isEnableElement={isEnableElement} />;
      case 'Version_2_5_7':
      case 'Version_2_5_8':
        return <Version_2_5_7 isEnableElement={isEnableElement} />;
      case 'Version_2_5_9':
        return <Version_2_5_9 isEnableElement={isEnableElement} />;
      case 'Version_2_5_10':
        return <Version_2_5_10 isEnableElement={isEnableElement} />;
      case 'Version_2_5_12':
        return <Version_2_5_12 isEnableElement={isEnableElement} />;
      default:
        return <Version_default isEnableElement={isEnableElement} />;
    }
  };

  const DownloadImage = (type) => {
    axios
      .get('didimos/' + key + '/results/' + type, {
        headers: {
          Authorization: `Bearer ${state.user?.accessToken}`,
          'didimo-follow-uri': 'false',
          'access-control-allow-origin': 'https://app.didimo.co',
          'content-type': 'application/json',
        },
      })
      .then((res) => {
        window.location.href = res.data.uri;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCreateEditModal = () => {
    setisEditModal(!isEditModal);
  };

  const handleDelete = () => {
    deleteData(`didimos/${key}`, state.user?.accessToken).then(() => {
      setisDeleteModal(!isDeleteModal);
      setShowDeleteNotification(true);
    });
  };

  const handleCreatedeleteModal = () => {
    setisDeleteModal(!isDeleteModal);
  };

  const customConfig = metaDataInfo?.meta_data.filter(
    (data) => data.definer === 'user'
  );

  return (
    <>
      <Helmet>
        <title>Didimo Information | Didimo</title>
      </Helmet>
      <Box
        className={'didimo-detail-container'}
        sx={{
          backgroundColor: 'transparent',
          py: 3,
          px: 3,
        }}
      >
        <Card>
          {isLoading ? (
            <div className="loading">
              <CircularProgress color="inherit" />
            </div>
          ) : hasData === true && metaDataInfo?.status != 'error' ? (
            <>
              <CardHeader
                title={
                  <div>
                    <ArrowBackIcon
                      className={classes.backArrow}
                      onClick={() => navigate(-1)}
                    />
                    didimo Information
                    {metaDataInfo ? ' - ' + metaDataInfo.key : 'N/A'}
                  </div>
                }
                action={
                  <div className={'butttonContainer'}>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={handleCreateEditModal}
                      disabled={isDemo}
                    >
                      {' '}
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      size="small"
                      onClick={handleCreatedeleteModal}
                      disabled={isDemo}
                    >
                      {' '}
                      Delete
                    </Button>
                  </div>
                }
              />

              <div className="details_body">
                <div className={'ddmoData-container'}>
                  <Box className="didimo_img_details" style={{ width: '100%' }}>
                    <CardMedia
                      className={classes.media}
                      image={didimoImage}
                      style={{ margin: '0 auto 50px' }}
                      title={key}
                    />
                    <div className="download_btn_group">
                      <Button
                        sx={{ textTransform: 'none' }}
                        onClick={() => DownloadImage('gltf')}
                        disabled={!transferFormats.includes('gltf')}
                      >
                        Download glTF
                      </Button>
                      <Button
                        sx={{ textTransform: 'none' }}
                        onClick={() => DownloadImage('fbx')}
                        disabled={!transferFormats.includes('fbx')}
                      >
                        Download FBX
                      </Button>
                    </div>
                    <div className="all_details">
                      <Box>
                        <div className="Meta-detail-1">
                          <div className="Meta_detail_items">
                            <div className="info-text">Key</div>
                            <div className="info-value">
                              {metaDataInfo ? metaDataInfo.key : 'N/A'}
                            </div>
                          </div>
                          <div className="Meta_detail_items">
                            <div className="info-text">Created on</div>
                            <div className="info-value">
                              {metaDataInfo && metaDataInfo.created_at
                                ? formatDate(new Date(metaDataInfo.created_at))
                                : 'N/A'}
                            </div>
                          </div>
                          <div className="Meta_detail_items">
                            <div className="info-text">
                              Download link expires on
                            </div>
                            <div className="info-value">
                              {metaDataInfo && metaDataInfo.expires_at
                                ? formatDate(new Date(metaDataInfo?.expires_at))
                                : 'N/A'}
                            </div>
                          </div>
                          <div className="Meta_detail_items">
                            <div className="info-text">API</div>
                            <div className="info-value">
                              {ddmMetaAPIVersion}
                            </div>
                          </div>
                          <div className="Meta_detail_items">
                            <div className="info-text">DGP Package</div>
                            <div className="info-value">{ddmMetaPackage}</div>
                          </div>
                          {metaDataInfo?.cost !== 0 && (
                            <div className="Meta_detail_items">
                              <div className="info-text">Cost</div>
                              <div className="info-value">
                                {metaDataInfo &&
                                  formatPoints(metaDataInfo.cost)}
                              </div>
                            </div>
                          )}
                        </div>

                        {customConfig && customConfig.length !== 0 && (
                          <div className="character-text">
                            <h4 className="m0">Custom Metadata</h4>
                            {customConfig.map((config) => (
                              <div className="Meta_detail_items">
                                <div className="info-text">{config.name}</div>
                                <div className="info-value">{config.value}</div>
                              </div>
                            ))}
                          </div>
                        )}
                      </Box>
                    </div>
                  </Box>

                  <CardContent className="details_container">
                    <div className="ddm-element-border">
                      <div>{renderDidimoElements()}</div>
                    </div>
                  </CardContent>
                </div>
              </div>
            </>
          ) : (
            <>
              <CardContent className={'didimos-details-nodata'} align="center">
                <div style={{ display: 'flex' }}>
                  <ArrowBackIcon
                    className={classes.backArrow}
                    onClick={() => navigate(-1)}
                  />
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    The Didimo you have selected is not availiable it may have
                    been deleted or expired.
                  </Typography>
                </div>

                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  <Button
                    color="secondary"
                    size="small"
                    variant="contained"
                    className={classes.buyBtnDesign}
                    onClick={HandleMyDidimo}
                    style={{
                      color: 'rgba(0, 0, 0, 0.87)',
                      padding: '4px 10px',
                      fontSize: '0.8125rem',
                      textTransform: 'none',
                    }}
                  >
                    View My Didimos
                  </Button>
                </Typography>
              </CardContent>
            </>
          )}
        </Card>
        {/* <Backdrop open={isLoading}>
              <CircularProgress color="inherit" />
            </Backdrop>*/}

        <EditDidimoModal
          ddmokey={key}
          isOpen={isEditModal}
          onCloseModal={handleCreateEditModal}
          accessToken={state.user?.accessToken}
          setDdmMetaInfo={setDdmMetaInfo}
        />
        <DeleteModal
          isOpen={isDeleteModal}
          onCloseModal={handleCreatedeleteModal}
          handleDelete={handleDelete}
        />
        <ErrorSnack
          open={showDeleteNotification}
          onClose={(e) => {
            setShowDeleteNotification(false);
          }}
          message="Didimo has been deleted successfully"
        />
      </Box>
    </>
  );
};

export default DidimoDetails;
