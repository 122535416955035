import React, { useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { format } from 'date-fns';
import MetaInfoVersion_2_5_0 from './DDMVersions/MetaInfoVersion_2_5_0';
import MetaInfoVersion_2_5_6 from './DDMVersions/MetaInfoVersion_2_5_6';
import MetaInfoVersion_2_5_7 from './DDMVersions/MetaInfoVersion_2_5_7';
import MetaInfoVersion_2_5_9 from './DDMVersions/MetaInfoVersion_2_5_9';
import MetaInfoVersion_2_5_10 from './DDMVersions/MetaInfoVersion_2_5_10';
import MetaInfoVersion_2_5_12 from './DDMVersions/MetaInfoVersion_2_5_12';

const MetaInfoModel = (props) => {
  const {
    isOpen,
    metadataStructure,
    metaDataInfo,
    ddmMetaAPIVersion,
    ddmMetaPackage,
    handleMetaInformation,
    version,
  } = props;

  const isEnableElement = (group, element) => {
    let headOnly = metaDataInfo?.meta_data.find(
      (f) => f.name === 'avatar_structure'
    );

    let metaInfo = metaDataInfo?.meta_data.find((f) => f.name === group);
    if (!metaInfo) {
      if (element !== 'photo') {
        metaInfo = metaDataInfo?.meta_data?.find((f) => f.name === element);
      }
      if (!metaInfo) {
        if (group === 'input_type') {
          if (metaDataInfo?.input_type === element) {
            return true;
          }
        } else if (group === 'transfer_formats') {
          return metaDataInfo?.transfer_formats?.find(
            (f) => f.name === element
          );
        }
      }
    }
    if (metaInfo) {
      if (
        group === 'gender' &&
        element === 'auto' &&
        !metaInfo.value.includes(';')
      ) {
        const genderArray = ['male', 'female'];
        return !genderArray.includes(metaInfo.value);
      } else if (
        group === 'garment' &&
        element === 'none' &&
        !metaInfo.value.includes(';')
      ) {
        const garmentArray = ['casual', 'sporty', 'business'];
        return !garmentArray.includes(metaInfo.value);
      } else if (
        group === 'hair' &&
        element === 'none' &&
        !metaInfo.value.includes(';')
      ) {
        const hairArray = [
          'hair_001',
          'hair_002',
          'hair_003',
          'hair_004',
          'hair_005',
          'hair_006',
          'hair_007',
          'hair_008',
          'hair_009',
          'hair_010',
          'hair_011',
          'baseball_cap',
        ];
        return !hairArray.includes(metaInfo.value);
      } else if (group === 'animation' && !metaInfo.value.includes(';')) {
        return metaInfo.name === element && metaInfo.value === 'true';
      } else if (metaInfo.value.includes(';')) {
        let allElements = metaInfo.value.split(';');
        return allElements.includes(element);
      } else if (group === 'body_pose') {
        if (headOnly.value === 'head-only') {
          return false;
        } else {
          return metaInfo.value === element;
        }
      } else {
        return metaInfo.value === element;
      }
    }
    return '';
  };

  const renderDidimoVersion = () => {
    console.log('in side :::::: renderDidimoVersion');

    switch (version) {
      case 'Version_2_5_0':
      case 'Version_2_5_1':
      case 'Version_2_5_2':
      case 'Version_2_5_3':
      case 'Version_2_5_4':
      case 'Version_2_5_5':
        return <MetaInfoVersion_2_5_0 isEnableElement={isEnableElement} />;
      case 'Version_2_5_6':
        return <MetaInfoVersion_2_5_6 isEnableElement={isEnableElement} />;
      case 'Version_2_5_7':
      case 'Version_2_5_8':
        return <MetaInfoVersion_2_5_7 isEnableElement={isEnableElement} />;
      case 'Version_2_5_9':
        return <MetaInfoVersion_2_5_9 isEnableElement={isEnableElement} />;
      case 'Version_2_5_10':
        return <MetaInfoVersion_2_5_10 isEnableElement={isEnableElement} />;
      case 'Version_2_5_12':
        return <MetaInfoVersion_2_5_12 isEnableElement={isEnableElement} />;
      default:
        return <MetaInfoVersion_2_5_12 isEnableElement={isEnableElement} />;
    }
  };

  return (
    <div>
      <Dialog
        className="detail-popup"
        open={isOpen}
        closeAfterTransition
        aria-labelledby="configure-api-key-title"
      >
        <DialogTitle className={'custom-font-family'}>
          <span className={'detail-header'}>Meta Information</span>
        </DialogTitle>
        <DialogContent>
          <div className="profile-detail custom-font-family">
            {metadataStructure ? renderDidimoVersion() : null}
            <div className="line">
              <hr />
            </div>
            <div className="info-detail-1">
              <div className="info-text">Key</div>
              <div className="info-value">
                {metaDataInfo ? metaDataInfo.key : 'N/A'}
              </div>
              <div className="info-text">Created on</div>
              <div className="info-value">
                {metaDataInfo && metaDataInfo.created_at
                  ? format(new Date(metaDataInfo?.created_at), 'MM/dd/yyyy')
                  : 'N/A'}
              </div>
              <div className="info-text">Download link expires on</div>
              <div className="info-value">
                {metaDataInfo && metaDataInfo.expires_at
                  ? format(new Date(metaDataInfo?.expires_at), 'MM/dd/yyyy')
                  : 'N/A'}
              </div>
              <div className="info-header">
                <h4 className="m0">Versions</h4>
              </div>
              <div className="info-text">API</div>
              <div className="info-value">{ddmMetaAPIVersion}</div>
              <div className="info-text">DGP Package</div>
              <div className="info-value">{ddmMetaPackage}</div>
              {metaDataInfo?.cost !== 0 && (
                <>
                  <div className="info-text">Cost</div>
                  <div className="info-value">
                    {metaDataInfo && metaDataInfo.cost}
                  </div>
                </>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleMetaInformation} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MetaInfoModel;
